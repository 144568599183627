import "../../css/landing.css";
export const Navbar = ({ ...props }) => {
  return (
    <div className="navbar">
      <svg
        className="logo-navbar"
        width="52"
        height="50"
        viewBox="0 0 52 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.25195 23.5898C1.25195 40.8771 18.7682 52.655 34.7778 46.1326L41.622 43.3442C42.2345 43.0947 42.9381 43.2771 43.3522 43.7927L48.0873 49.6889C48.4847 50.1837 49.151 50.3738 49.7496 50.1632C50.3481 49.9526 50.7486 49.3871 50.7486 48.7526L50.7486 25.3642C50.7486 10.9407 39.056 -0.751951 24.6325 -0.75195C11.7198 -0.75195 1.25195 9.71585 1.25195 22.6285L1.25195 23.5898Z"
          fill="white"
        />
        <path
          d="M29.7948 16.5084C27.1963 16.0727 24.5294 16.0727 21.9308 16.5084C20.9815 16.6675 20.2255 17.3963 20.0351 18.3413C19.1631 22.6705 19.1155 27.1252 19.8949 31.472L20.2122 33.2417C20.31 33.7874 20.9753 34.0055 21.3772 33.6237L25.2072 29.9852C25.5746 29.6362 26.151 29.6362 26.5184 29.9852L30.3484 33.6237C30.7504 34.0055 31.4156 33.7874 31.5135 33.2417L31.8308 31.472C32.6101 27.1252 32.5625 22.6704 31.6905 18.3413C31.5002 17.3963 30.7442 16.6675 29.7948 16.5084Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
